$menu-height: 40px;
$header-height: 40px;
$footer-height: 80px;
$search-bar-height: 40px;
$page-padding: 5px;
$responsive-max-width: 1024px;

$xs: 490px;
$sm: 768px;
$md: 992px;
$lg: 1024px;
