$body-bg: #f5f5f5;
$body-color: #002453;
$primary: #009ddc;
$secondary: #d9d9d9;
$success: #2ccc70;
$danger: #ba1e1e;
$warning: #ebab53;
$info: #1acdb5;
$light: #f5f5f5;
$dark: #002453;
$body-color-second: #9da3aa;
$sweet-green:#1ACDB5;
$clear-text: #808080;
$scrollbar-background: #d8d8d8;
$scrollbar-handle: #b3b3b3;
$light-button-border: #f0f0f0;
$light-button-text: #666;
$shadow: #adadad;
$shadow-over: #808080;
$dark-green: #007e30;
$dark-primary: #0a6686;
$border-color: #ddd;
$rental-event:#F6BF26;
$maintenance-event:#00a7e1;
$box-colors: #01698b;
$fresh-blue: #1da2e3;
$old-fresh-blue: #6eaecd;


$chat-message-background: #f1f6f9;
$chat-message-text: #232524;
$chat-message-text-light: #3b3d3c;