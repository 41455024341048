@import '~bootstrap/scss/bootstrap';
@import 'src/colors.scss';
@import 'src/sizes.scss';

/*
  Fonts
*/
@font-face {
  font-family: 'DINPro-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('DINPro-Medium'), url('/assets/fonts/DINPro-Medium_13936.woff') format('woff');
}

@font-face {
  font-family: 'Days One Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Days One Regular'), url('/assets/fonts/DaysOne-Regular.woff') format('woff');
}

/* remove focus input blue border */
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

/* width */
::-webkit-scrollbar {
  width: 0 !important;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: $scrollbar-background;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $scrollbar-handle;
  border-radius: 4px;
}

body {
  font-family: 'DINPro-Medium';
  font-size: 1em;
  @media screen and (max-width: 768px) {
    font-size: 1em;
  }
  // background: #eee url('assets/images/repeat-bg.jpg') repeat top left;
  // background-size: 500px 500px;
  background-color: #fff;
  // background: url(/assets/images/login-background.jpg) no-repeat center center fixed;
  background-size: cover;
  overflow-y: scroll;
}

h3 {
  font-size: 1.35em;
  color: $primary;
}

h2 {
  font-family: 'Days One Regular';
}

h5 {
  font-family: 'Days One Regular';
  font-size: 1rem;
  margin-bottom: 0px;
}

h6 {
  font-family: 'Days One Regular';

  font-size: 1.1em;
  margin-bottom: 2px;
}

.cursor-pointer {
  cursor: pointer;
}

/* Forms */
.form-control {
  border-radius: 5px;
  border: 1px solid $border-color;
}

.form-control-cell {
  width: 100%;
  min-height: 60px;
}

.form-group {
  margin-bottom: 0;
  label {
    margin-bottom: 0;
  }
}

a.a-link:hover {
  cursor: pointer;
  text-decoration: underline !important;
}

a.a-link-standard {
  color: $primary !important;
  text-decoration: underline !important;
  cursor: pointer;
}

/* Override bootstrap components */
.btn-secondary {
  background-color: $shadow;
  border: 1px solid $shadow;
  box-shadow: 0px 0px 0px 0px white;
}

.btn-secondary:hover {
  background-color: $shadow-over;
  border: 1px solid $shadow-over;
}

.btn-nc {
  border-radius: 18px;
  box-shadow: 0px 2px 5px 1px $secondary;
  color: white;
  min-width: 80px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1em;
  height: 40px;
  line-height: 26px;
  label {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }
  // @media screen and (max-width: 860px) {
  //   label {
  //     display: none;
  //   }
  // }

  cursor: pointer;
  &:active,
  &:hover {
    color: white;
  }
  &.icon {
    min-width: 45px;
    padding: 5px;
    i {
      font-size: 1.2em;
      margin: 2px;
    }
  }
  fa-icon {
    font-size: 1em;
  }
  &.btn-select {
    margin: 10px;
    border-radius: 18px !important;
    height: unset;
    min-height: 40px;
  }
}

.btn-nc-ext {
  border-radius: 18px;
  box-shadow: 0px 2px 5px 1px $secondary;
  color: white;
  min-width: 80px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1em;
  min-height: 40px;
  line-height: 26px;
  label {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }
  // @media screen and (max-width: 860px) {
  //   label {
  //     display: none;
  //   }
  // }

  cursor: pointer;
  &:active,
  &:hover {
    color: white;
  }
  &.icon {
    min-width: 45px;
    padding: 5px;
    i {
      font-size: 1.2em;
      margin: 2px;
    }
  }
  fa-icon {
    font-size: 1em;
  }
  &.btn-select {
    margin: 10px;
    border-radius: 18px !important;
    height: unset;
    min-height: 40px;
  }
}

.input-group-append .btn-datetimepicker {
  border-radius: 0px 3px 3px 0px;
  border: 1px solid $border-color;
  padding-top: 4px;
  padding-bottom: 4px;
  height: calc(1.5em + 0.75rem + 2px);
  padding-left: 10px;
}

.btn-view {
  background-color: #55b7da;
  padding: 0px 10px 0px 10px;
  height: 20px !important;
  line-height: 15px;
}

.btn-arrow {
  cursor: pointer;
}

.badge-outline-dark {
  background: white;
  color: $dark;
  border: 2px solid $dark;
}

.badge-outline-info {
  background: white;
  color: $dark-primary;
  border: 2px solid $dark-primary;
}

.badge-outline-success {
  background: white;
  color: $dark-green;
  border: 2px solid $dark-green;
}

.badge-outline-secondary {
  background: white;
  color: $secondary;
  border: 2px solid $secondary;
}

.horizontal-center {
  display: flex;
  justify-content: center;
}

.horizontal-left {
  display: flex;
  justify-content: flex-start;
}

.horizontal-right {
  display: flex;
  justify-content: flex-end;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.vertical-bottom {
  display: flex;
  align-items: flex-end;
}

.card {
  box-shadow: 0px 2px 5px 1px $secondary;
}

$bt-primary-color: #00a7e1;
.btn-primary {
  background-color: $bt-primary-color;
  border-color: $bt-primary-color;
}

.btn-light {
  background-color: white;
  color: $light-button-text !important;
  box-shadow: 0px 0px 0px 0px white;
  &.active {
    background-color: $light-button-text !important;
    color: white !important;
  }
  &:hover {
    color: white !important;
  }
}

.btn-dark {
  background-color: white;
  color: $dark !important;
  box-shadow: 0px 0px 0px 0px white;
  &.active {
    background-color: $dark !important;
    color: white !important;
  }
  &:hover {
    background-color: $dark !important;
    color: white !important;
  }
}

.btn-danger {
  &.radio {
    background-color: #eabbbb;
    color: $danger !important;
    border: 0px;
    &:hover {
      background-color: #dd9a9a !important;
      color: $danger !important;
    }
    &.active {
      background-color: $danger !important;
      color: white !important;
    }
  }
}

.btn-success {
  &.radio {
    background-color: #baefe8;
    color: #007733 !important;
    border: 0px;
    &:hover {
      color: #007733 !important;
      background-color: #86dace !important;
    }
    &.active {
      background-color: $info !important;
      color: white !important;
    }
  }
}

.overflow-text {
  white-space: nowrap;
  overflow: hidden;
}

.btn-file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    font-size: 0.8em;
    font-weight: 700;
    width: 8.5em;

    color: white;
    background-color: $secondary;
    display: inline-block;
    border-radius: 0.5em;
    padding-top: 15px;
  }

  &:focus + label,
  & + label:hover {
    background-color: $primary;
  }
}

.btnSelectActive {
  background-color: $sweet-green !important;
}

.btn-inactive {
  background-color: $secondary;
  color: #fff !important;
}

.linkInputIcon {
  font-size: 1.4rem;
  right: 25px;
  top: 2px;
  position: absolute;
  cursor: pointer;
  color: #d9d9d9 !important;
}

.flipImage {
  transform: scaleX(-1);
}

.asterisk-nc {
  color: red;
}

//*****************************************************************//

.ruptureTitle {
  width: 100%;
  font-family: 'Days One Regular';
  background: linear-gradient(90turn, #ddd, #f8f8f8, #f8f8f8, #f8f8f8);
  border-bottom: 1px solid #ccc;
  line-height: 20px;
  height: 20px;
  padding-left: 10px;
  vertical-align: middle;
  font-size: 0.8rem;
  margin-bottom: 5px;
  text-align: left;
}

.pageClub {
  // barre de menu
  min-height: calc(100vh - #{$menu-height});
  // margin-top: $menu-height;
  padding: $page-padding;

  // width: 100vw;
  background-color: #fff;
  // background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);

  text-align: center;

  position: relative;
  // @media screen and (min-width: $responsive-max-width) {
  //   width: $responsive-max-width;
  //   margin-left: calc((100vw - #{$responsive-max-width}) / 2);
  // }
}

.pageClub.noPadding {
  padding: 0px !important;
}

.searchBar {
  position: fixed;
  z-index: 999;
  top: $menu-height;
  height: $search-bar-height;
  line-height: $search-bar-height;
  width: 100vw;

  vertical-align: middle;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 1px solid $secondary;
  background: linear-gradient(90turn, #e8e8e8, #fff, #fff, #fff);
  font-size: 0.8rem;

  @media screen and (min-width: $responsive-max-width) {
    font-size: 1rem;
    width: $responsive-max-width;
    margin-left: calc((100vw - #{$responsive-max-width}) / 2);
  }
}

.searchBlock {
  position: fixed;
  top: calc(#{$menu-height} + #{$search-bar-height});
  left: 0px;

  width: 100%;
  line-height: 30px;
  min-height: 30px;
  vertical-align: middle;
  padding-left: 5px;
  padding-right: 20px;
  border-bottom: 1px solid #aaa;
  background: linear-gradient(90turn, #e8e8e8, #fff, #fff, #fff);
  box-shadow: 0px 1px 3px 1px #aaa;
  z-index: 999;
  @media screen and (min-width: $responsive-max-width) {
    font-size: 1rem;
    width: $responsive-max-width;
    margin-left: calc((100vw - #{$responsive-max-width}) / 2);
  }
}

.testContent {
  line-height: calc(100vh - #{$menu-height} - calc(2 * #{$page-padding}) - 4px);
  text-align: center;
  vertical-align: middle;
  font-size: 1rem;
  border: 1px solid #aaa;
}

.updateAvailable {
  position: fixed;

  height: 60px;
  line-height: 60px;
  vertical-align: middle;
  padding-left: 5px;
  background-color: $sweet-green;
  color: #fff;
  font-size: 0.9rem;
  width: 100vw;
  bottom: 0px;
  @media screen and (min-width: $responsive-max-width) {
    font-size: 1rem;
    width: $responsive-max-width;
    margin-left: calc((100vw - #{$responsive-max-width}) / 2);
  }

  z-index: 1010;
  display: flex;
  align-items: center;
}

.msgVersion {
  flex-grow: 1;
  font-size: 0.9rem;
  line-height: 20px;
}

.btnInstall {
  flex: 0 0 80px;
}

.versionPwa {
  position: fixed;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
  text-align: left;
  padding-left: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #555;
  font-size: 0.7rem;
  width: 55px;
  // bottom: calc(#{$footer-height} - 1px);
  bottom:0;
  z-index: 1000;
  right: 0px;
  border-radius: 5px 0px 0px 0px;
  @media screen and (min-width: $responsive-max-width) {
    bottom: 0px;
    // right: calc((100vw - #{$responsive-max-width}) / 2);
  }
}

@keyframes slidein {
  0% {
    bottom: -70px;
  }

  50% {
    bottom: -70px;
  }

  100% {
    bottom: 0px;
  }
}

.bannerBoat {
  position: relative;
  height: 290px;
  background: #fff url('assets/images/banner.jpg') no-repeat center;
  background-size: 150%;
  width: 100%;
  text-align: center;
  padding-top: $menu-height;
  @media screen and (min-width: $responsive-max-width) {
    background: #fff url('assets/images/banner-cocotte.jpg') no-repeat bottom right;
    background-size: 150%;
    width: $responsive-max-width;
    margin-left: calc((100vw - #{$responsive-max-width}) / 2);
  }
}

.bannerBoat.large {
  height: 305px;
}

.bannerSlider {
  // width: 80%;
  // height: calc( 240px - #{$menu-height} );
  margin: auto;
  margin-top: $header-height;
  background-color: #fff;
  border-radius: 10px;
  @media screen and (min-width: $responsive-max-width) {
    // margin-left: 10%;
  }
}

.boxBannerSlide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: calc(240px - #{$menu-height});
  position: relative;
  overflow: hidden;
}

.bannerImgSlide {
  height: 230px;
  //min-width: 100%;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
  overflow: hidden;
}

.returnBtn {
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  position: absolute;
  top: 60px;
  left: 4%;
  margin-left: -7px;
}

.boatDetails {
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  position: absolute;
  top: 60px;
  right: 4%;
}

// .bannerSeparation{
//   height: 40px;
//   line-height: 40px;
//   background-color: #fff;
//   // border-top: 1px solid #ddd;
//   // border-bottom: 1px solid #ddd;
//   width:100%;
//   // box-shadow: 0px 4px 10px #eee;
//   position:relative;
//     z-index:2;

// }

.pageHome {
  // barre de menu
  //min-height: calc(100vh - #{$menu-height} - 140px);
  min-height: 100vh;
  position: relative;
  background-color: #fff;

  // border-left: 1px solid rgba(0, 0, 0, 0.125);
  // border-right: 1px solid rgba(0, 0, 0, 0.125);

  @media screen and (min-width: $responsive-max-width) {
    // width: $responsive-max-width;
    // margin-left: calc((100vw - #{$responsive-max-width}) / 2);
    // margin-left:auto;
    // margin-right:auto;
  }
}

.bookingList {
  text-align: left;
  margin-top: 10px;

  .noBooking {
    padding: 10px;
    text-align: left;
    color: $clear-text;
  }

  .boatBox {
    display: flex;
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 2px;
    min-height: 70px;

    //background-color: #fff;
    background: linear-gradient(90turn, #eee, #f8f8f8, #f8f8f8, #f8f8f8);
  }

  .boatBox:hover {
    cursor: pointer;
    background-color: #eee;
  }

  .dateTitle {
    color: $dark;
    font-size: 0.7rem;
    font-weight: bold;
  }

  .imgBoat {
    flex: 0 0 80px;
  }

  .imgBoat img {
    max-width: 100px;
    max-height: 60px;
  }

  .rentalTitle {
    flex-grow: 1;
    margin-left: 10px;
  }

  .boatName {
    font-size: 0.8rem;
    font-weight: bold;
    color: $primary;
  }

  .boatModel {
    font-size: 0.7rem;
    font-weight: bold;
  }
}

.smallBookingList {
  text-align: left;
  margin-top: 0px;

  .noBooking {
    padding: 10px;
    text-align: left;
    color: $clear-text;
  }

  .boatBox {
    display: flex;
    margin-bottom: 3px;
    padding: 3px;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    min-height: 30px;

    //background-color: #fff;
    //background: linear-gradient(90turn, #eee, #f8f8f8, #f8f8f8, #f8f8f8);
  }

  .boatBox:hover {
    cursor: pointer;
    background-color: #eee;
  }

  .dateTitle {
    color: #888;
    font-size: 0.7rem;
    //font-weight: bold;
  }

  .imgBoat {
    flex: 0 0 40px;
  }

  .imgBoat img {
    max-width: 50px;
    max-height: 30px;
  }

  .rentalTitle {
    flex-grow: 1;
    margin-left: 5px;
  }

  .boatInformations {
    line-height: 15px;
  }

  .boatName {
    font-size: 0.7rem;
    //font-weight: bold;
    color: $primary;
  }

  .boatModel {
    color: #888;
    font-size: 0.6rem;
    //font-weight: bold;
  }
}

.iconButtonPlusBottom {
  margin: auto;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  line-height: 39px;
  font-size: 1rem;
  text-align: center;
  background-color: #005a86;
  color: #fff;
  box-shadow: 3px 3px 5px #ccc;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -20px;
  z-index: 990;
}

.hideBottom {
  position: fixed;
  height: 60px;
  bottom: 0px;
  width: 100vw;
  background-color: transparent;
  background: linear-gradient(to bottom, transparent, white, white);
}

.imageTest {
  position: fixed;
  bottom: 10px;
  left: 20px;
  height: 50px;
  width: 50px;
  border: 1px solid red;
}

.ruptureDate {
  width: 100%;
  font-family: 'Days One Regular';
  background: linear-gradient(90turn, #ddd, #f8f8f8, #f8f8f8, #f8f8f8);
  border-bottom: 1px solid #ccc;
  line-height: 20px;
  height: 20px;
  padding-left: 10px;
  font-size: 0.7rem;
  margin-bottom: 5px;
}

.nc-filter-clock {
  background: url(/assets/icons/ic_schedule.svg) no-repeat;
  background-color: white;
  background-position-x: 48px;
  background-position-y: center;
  height: 40px;
  -webkit-appearance: none;
  border-radius: 18px;
  padding-left: 10px;
  min-width: 82px;
  border: 1px solid $border-color;
  font-size: 1em;
  color: $body-color;
}

.input-group-append .btn-datetimepicker {
  border-radius: 0px 15px 15px 0px;
  border: 1px solid $border-color;
  padding-top: 4px;
  padding-bottom: 4px;
  height: calc(1.5em + 0.75rem + 2px);
  padding-left: 10px;
}

.informationBloc {
  width: 100%;
  // min-height: calc(100vh - #{$menu-height} - 180px);
  // background-color: #cbe5f2;
  padding: 10px;
}
// .add-anomaly-container{
//   margin:0 10px;
// }

.boxDocument {
  display: flex;
  align-items: center;
  // justify-content: center;
  margin-bottom: 5px;
  font-size: 0.9rem;
  cursor: pointer;
}

.iconDoc {
  flex: 0 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconDoc img {
  width: 40px;
}

.max-heigth-150 {
  max-height: 150px;
}

.dotted-line {
  border-bottom: 1px dotted #ccc;
}

.page {
  overflow-y: scroll;
  // barre de menu
  @media (max-width: $responsive-max-width) {
    height: calc(100vh - calc((#{$menu-height} + #{$footer-height})));
  }

  @media (min-width: $responsive-max-width) {
    height: calc(100vh - #{$menu-height});
    // height: calc(100vh - #{$menu-height} + 8px );
  }
  width: 100%;
  background-color: rgba($color: #fff, $alpha: 1);
  background-clip: border-box;

  // text-align: center;

  position: relative;
  @media screen and (min-width: $responsive-max-width) {
    background-color: rgba($color: #fff, $alpha: 1);
    font-size: 1rem;
    // width: $responsive-max-width;
    // margin-left: calc((100vw - #{$responsive-max-width}) / 2);
  }
}

.page.noPadding {
  padding: 0px !important;
  border: none;
}

#container {
  width: 100%;
  height: 100%;
  @media screen and (min-width: $responsive-max-width) {
    width: $responsive-max-width;
  }
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;

  // padding-top: $menu-height;
  // min-height: calc(100vh - #{$footer-height});
  // width: 100vw;
  // position: absolute;
  // top: 0px;
  // left: 0px;
  // @media screen and (min-width: $responsive-max-width) {
  //   background: url(/assets/images/login-background.jpg) no-repeat center center fixed;
  //   background-size: cover;
  // }

  // background-color: #fff;
}

.color-disabled {
  color:#777;
}